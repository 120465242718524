<template>
    <MbscSnackbar display="top"
                  cssClass="mobiscroll-snackbar-dark"
                  :message="snackbarMessage"
                  :isOpen="isSnackbarOpen"
                  @close="isSnackbarOpen = false" />
    <MbscAlert display="center"
               theme="ios"
               themeVariant="light"
               :message="alertMessage"
               :isOpen="isAlertOpen" />

    <div class="pre-loader" v-if="isLoading"></div>

    <div class="site-container">
        <UserMenu />
        <div class="admin-page-content"
             :class="(isAllowedToLWA == false) || (isAllowedToLWA == true && hasRefreshToken == false) ? 'amazon-banner-spacer' : ''">
            <ImpersonateBanner />
            <!-- link Amazon account banner -->
            <div class="amazon-banner"
                 v-if="isAllowedToLWA == false">
                <p class="mb-0">Upgrade your subscription to sync products from your</p>
                <img src="@/assets/images/Amazon_logo.png" alt="Amazon" class="amazon-logo" />
                <p class="me-3 mb-0">seller account</p>
                <button type="button"
                        id="btn-upgrade-subscription"
                        class="btn btn-sm btn-secondary hvr-grow-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#upgrade-modal"
                        @click="onUpgradeSubscriptionClicked()">
                    Upgrade
                </button>
            </div>
            <div class="amazon-banner"
                 v-if="isAllowedToLWA == true && hasRefreshToken == false">
                <p class="mb-0">For the best experience, link your</p>
                <img src="@/assets/images/Amazon_logo.png" alt="Amazon" class="amazon-logo" />
                <p class="me-3 mb-0">seller account</p>
                <button type="button"
                        id="btn-link-with-seller-account"
                        class="btn btn-sm amazon-btn hvr-grow-sm"
                        @click="onLinkAccountClicked()">
                    Link Account
                </button>
            </div>

            <div class="container">
                <UserProfileDropdown />
                <div class="row align-items-center mb-5">
                    <div class="col-md-auto mb-3 mb-md-0">
                        <h2 class="m-0 text-strong"><i class="fal fa-shop"></i> Manage Products</h2>
                    </div>
                    <div class="col-md-auto">
                        <div class="dropdown">
                            <button type="button"
                                    id="btn-new"
                                    class="btn dropdown-toggle btn-sm px-3 btn-primary btn-round hvr-grow mobile-100"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fal fa-plus me-2"></i> New
                            </button>
                            <ul class="dropdown-menu">
                                <li>
                                    <a class="dropdown-item"
                                       id="btn-import"
                                       href="#"
                                       data-bs-toggle="modal"
                                       data-bs-target="#import-products"><i class="fal fa-file-import fa-fw"></i> Import Products</a>
                                </li>
                                <li>
                                    <a class="dropdown-item"
                                       id="btn-add"
                                       href="#"
                                       data-bs-toggle="modal"
                                       data-bs-target="#edit-product"
                                       @click.prevent="onAddProductClicked"><i class="fal fa-plus fa-fw"></i> Add One Product</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <ul class="nav nav-underline mb-4">
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#" id="products-tab" data-bs-toggle="tab" data-bs-target="#products-tab-pane" role="tab" aria-controls="products-tab-pane" aria-selected="true">Manage Products</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#" id="import-tab" data-bs-toggle="tab" data-bs-target="#import-tab-pane" role="tab" aria-controls="import-tab-pane" aria-selected="false">Import Status</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane fade show active"
                         id="products-tab-pane"
                         role="tabpanel"
                         aria-labelledby="products-tab"
                         tabindex="0">
                        <div class="tab-pane fade show active"
                             id="products-tab-pane"
                             role="tabpanel"
                             aria-labelledby="products-tab"
                             tabindex="0"
                             v-if="isAllowedToLWA == true && hasRefreshToken == true">
                            <div class="alert my-4 sync-alert"
                                 :class="{'sync-on' : isSyncingCatalog}">
                                <div class="form-check form-switch form-switch-lg">
                                    <input class="form-check-input"
                                           type="checkbox"
                                           role="switch"
                                           id="tgl-catalog-sync"
                                           v-model="isSyncingCatalog"
                                           @change="onCatalogSyncChanged()">
                                    <label class="form-check-label m-0 text-600" for="tgl-catalog-sync">Catalog Sync: {{ isSyncingCatalog ? 'On' : 'Off' }} </label>
                                    <a href="#" data-bs-toggle="modal" data-bs-target="#catalog-sync" class="ms-2 text-body"><i class="far fa-question-circle fa-lg"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="input-group input-group-lg mb-4">
                            <input type="text"
                                   class="form-control"
                                   placeholder="Search products..."
                                   aria-label="Search supplier users..."
                                   v-model="searchValue"
                                   v-on:keyup.enter="reloadTable()">
                            <button class="btn btn-white"
                                    id="btn-search"
                                    type="button"
                                    @click="reloadTable()">
                                <i class="fal fa-search"></i>
                            </button>
                        </div>
                        <div class="row align-items-center mb-4">
                            <div class="col-lg-3 mb-3 mb-lg-0">
                                <div class="dropdown w-100">
                                    <button class="btn btn-white btn-sm dropdown-toggle w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span>Bulk Actions ( <span class="text-strong">{{selectedProducts.length}}</span> selected )</span>
                                    </button>
                                    <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                                        <li v-if="selectedProducts.length > 0">
                                            <a class="dropdown-item"
                                               data-bs-toggle="modal"
                                               data-bs-target="#delete-confirm"
                                               href="#"><i class="far fa-trash-check"></i> Delete Selected</a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item"
                                               href="#"
                                               @click.prevent="onDeselectAllClicked">
                                                <i class="far fa-circle-exclamation-check"></i> Deselect All
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-auto">
                                Maximum allowed products is {{maxAllowedAsins}}.
                            </div>
                        </div>

                        <div class="card">
                            <DataTable :columns="tableColumns"
                                       :options="tableOptions"
                                       class="table table-hover align-middle m-0"
                                       width="100%"
                                       ref="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>
                                            <input type="checkbox"
                                                   id="cb-select-all"
                                                   name="cb-select-all"
                                                   value="x">
                                        </th>
                                        <th>Product</th>
                                        <th>Brand</th>
                                        <th>MAP</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                            </DataTable>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="import-tab-pane" role="tabpanel" aria-labelledby="import-tab" tabindex="0">
                        <div class="card">
                            <DataTable :columns="tableColumnsImportLog"
                                       :options="tableOptionsImportLog"
                                       class="table table-hover align-middle m-0"
                                       width="100%"
                                       ref="tableImportLog">
                                <thead>
                                    <tr>
                                        <th>Import Date</th>
                                        <th>File Name</th>
                                        <th>Status</th>
                                        <th>Total Records</th>
                                        <th>Succeeded</th>
                                        <th>Failed</th>
                                    </tr>
                                </thead>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <UserFooter />
    </div>

    <!-- New/Edit Product Modal -->
    <div class="modal fade"
         id="edit-product"
         data-bs-backdrop="static"
         tabindex="-1"
         aria-labelledby="edit-product"
         aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-3">{{ isEditing ? 'Edit' : 'New'}} Product</h1>
                    <button type="button"
                            ref="closeModalButton"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            @click="isEditing = false"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md mb-3 mb-md-0">
                            <label for="asin" class="form-label">ASIN<sup class="text-danger">*</sup></label>
                            <input type="text"
                                   class="form-control"
                                   id="asin"
                                   placeholder="ASIN"
                                   v-model="addOrEditProduct.asin"
                                   v-bind:class="{'is-invalid' : !validAsin() && asinBlured}"
                                   v-on:blur="asinBlured = true"
                                   required
                                   :disabled="isEditing == true">
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must be 10 characters.
                            </div>
                        </div>
                        <div class="col-md mb-3 mb-md-0">
                            <label for="map" class="form-label">MAP<sup class="text-danger">*</sup></label>
                            <div class="input-group">
                                <span class="input-group-text">$</span>
                                <input type="text"
                                       class="form-control"
                                       id="map"
                                       placeholder="MAP"
                                       v-model="addOrEditProduct.map"
                                       v-bind:class="{'is-invalid' : !validMap() && mapBlured}"
                                       v-on:blur="mapBlured = true"
                                       required>
                                <div class="invalid-feedback">
                                    <i class="fas fa-exclamation-circle"></i> This field is required and must be a number with two decimal places.
                                </div>
                            </div>
                        </div>
                        <div class="col-md mb-3 mb-md-0">
                            <label for="sku" class="form-label">SKU<sup class="text-danger">*</sup></label>
                            <input type="text"
                                   class="form-control"
                                   id="sku"
                                   placeholder="SKU"
                                   v-model="addOrEditProduct.sku"
                                   v-bind:class="{'is-invalid' : !validSku() && skuBlured}"
                                   v-on:blur="skuBlured = true"
                                   :disabled="addOrEditProduct.catalogStatus == 'Active'"
                                   required>
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must be 254 characters or less.
                            </div>
                        </div>
                        <div class="col-md mb-3 mb-md-0">
                            <label for="isEnforcementEnabled" class="form-label d-block">Enforce</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input"
                                       type="checkbox"
                                       role="switch"
                                       id="isEnforcementEnabled"
                                       v-model="addOrEditProduct.isEnforcementEnabled"
                                       :disabled="addOrEditProduct.isBrandApproved == false"
                                       checked />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button"
                            class="btn btn-outline-secondary btn-round me-2"
                            data-bs-dismiss="modal">
                        Cancel
                    </button>
                    <button type="button"
                            class="btn btn-secondary btn-round"
                            id="btn-submit"
                            @click="onSubmitClicked">
                        Save Product
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Import Modal -->
    <div class="modal fade"
         id="import-products"
         data-bs-backdrop="static"
         tabindex="-1"
         aria-labelledby="import-products"
         aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-3">Import Products</h1>
                    <button type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref="closeImportLogModalButton"></button>
                </div>
                <div class="modal-body">
                    <div class="alert alert-secondary">
                        <div class="row">
                            <div class="col-auto">
                                <i class="far fa-info-circle fa-2x text-muted"></i>
                            </div>
                            <div class="col">
                                <ul class="m-0 ps-2">
                                    <li>You can have up to {{ maxAllowedAsins }} products</li>
                                    <li>Products will never be deleted via import</li>
                                    <li>If an asin already exists in the table, the product's sku and map will be updated with the values in the spreadsheet</li>
                                    <li>If there are duplicate asins in the spreadsheet, the upload will fail</li>
                                    <li>Asin must be 10 alpahnumeric characters</li>
                                    <li>Sku must be at least 1 character and at most 254 characters</li>
                                    <li>Map must be a decimal with exactly two decimal points</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="fancy-file text-center"
                         v-if="selectedFile == null">
                        <input accept=".csv"
                               type="file"
                               @change="onFileChanged($event)" />
                        <p>Drag file here or click the button below</p>
                        <button type="button"
                                class="btn btn-primary btn-round">
                            <i class="far fa-file-upload"></i> Choose File
                        </button>
                    </div>
                    <div v-if="selectedFile != null">
                        <span>
                            {{ selectedFile.name }}
                            <button type="button"
                                    class="btn btn-sm btn-white border-0"
                                    @click="selectedFile = undefined">
                                <i class="fa-regular fa-times"></i>
                            </button>
                        </span>
                    </div>

                    <div class="my-4 text-center">
                        <a role="button" href="/api/product/template" class="body-text-link">Download example template</a>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button"
                            class="btn btn-outline-secondary btn-round me-2"
                            data-bs-dismiss="modal"
                            @click="selectedFile = undefined">
                        Cancel
                    </button>
                    <button type="button"
                            id="btn-import"
                            class="btn btn-secondary btn-round"
                            @click="onImportClicked()">
                        Import
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Delete Confirm Modal -->
    <div class="modal fade"
         id="delete-confirm"
         data-bs-backdrop="static"
         tabindex="-1"
         aria-labelledby="delete-confirm"
         aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-3">Delete Products</h1>
                    <button type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref="closeImportModalButton"></button>
                </div>
                <div class="modal-body">
                    <h2 class="text-center mb-5 mt-3">Are you sure you want to delete <strong>{{ selectedProducts.length }}</strong> products?</h2>
                    <div class="d-flex align-items-center justify-content-center">
                        <button type="button"
                                class="btn btn-outline-secondary btn-round me-2"
                                data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button type="button"
                                class="btn btn-primary btn-round"
                                @click.prevent="onDeleteSelectedProductsClicked()">
                            Delete Products
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Upgrade Modal -->
    <div class="modal fade"
         id="upgrade-modal"
         tabindex="-1"
         aria-labelledby="upgrade-modal"
         aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header pb-0">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <img src="@/assets/images/upgrade.png" style="height: 300px; margin: -50px auto 0 auto; display: block;" alt="upgrade" />
                    <h5 class="text-center mb-3 text-700">Your upgrade request has been submitted</h5>
                    <p class="text-center">An admin has been notified and will reach out to you shortly.</p>
                    <p class="text-center"><i class="far fa-phone"></i> 260-222-8241</p>
                    <div class="d-flex align-items-center justify-content-center">
                        <button type="button" class="btn btn-secondary btn-round px-5 text-600" data-bs-dismiss="modal">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Catalog Sync Modal -->
    <div class="modal fade" id="catalog-sync" tabindex="-1" aria-labelledby="catalog-sync" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-3">Catalog Sync</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class='row'>
                        <div class='col-md mb-3 md-md-0'>
                            <div class="card p-3 h-100">
                                <h5 class="text-600"><i class="far fa-check text-success me-2"></i> If turned on:</h5>
                                <ul class='text-500 mb-0 ps-5'>
                                    <li class='mb-3'>Your catalog will be synced at each price check starting with the next price check.</li>
                                    <li class='mb-3'>Synced active products in your catalog will have real time price checks.</li>
                                    <li class='mb-3'>Synced products in your catalog that are new will have MAP set to the listing price on the inital sync. Future syncs will not update the MAP of existing synced products. If the product does not have a listing price, the MAP for the product will be set to $0. Products with a MAP of $0 will not be monitored.</li>
                                    <li class='mb-3'>If you exceed your maximum allowed products, catalog sync will be turned off and the synced products will be removed from MapAuthority.</li>
                                </ul>
                            </div>
                        </div>
                        <div class='col-md mb-3 md-md-0'>
                            <div class="card p-3 h-100">
                                <h5 class="text-600"><i class="far fa-times text-danger me-2"></i> If turned off:</h5>
                                <ul class='text-500 mb-0 ps-5'>
                                    <li class='mb-3'>Synced products will be removed from MapAuthority.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Turn Off Catalog Sync Modal -->
    <div class="modal fade"
         id="turn-off-confirm"
         data-bs-backdrop="static"
         tabindex="-1"
         aria-labelledby="turn-off-confirm"
         aria-hidden="true"
         ref="turnOffCatalogSyncModal">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-3">Turn Off Catalog Sync</h1>
                    <button type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref="closeImportModalButton"
                            @click.prevent="isSyncingCatalog = !isSyncingCatalog"></button>
                </div>
                <div class="modal-body">
                    <h2 class="text-center mb-5 mt-3">Are you sure you want to turn off Catalog Sync? Synced products will be removed from MapAuthority.</h2>
                    <div class="d-flex align-items-center justify-content-center">
                        <button type="button"
                                class="btn btn-outline-secondary btn-round me-2"
                                data-bs-dismiss="modal"
                                @click.prevent="isSyncingCatalog = !isSyncingCatalog">
                            Cancel
                        </button>
                        <button type="button"
                                class="btn btn-primary btn-round"
                                data-bs-dismiss="modal"
                                @click.prevent="updateCatalogSync()">
                            Turn Off
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .brand-name-column, .sku-column {
        min-width: 150px;
    }
</style>

<script lang="ts" setup>

    import { CustomRegex } from '@/assets/CustomRegex'
    import { ProductDto } from '@/assets/types'

    import ImpersonateBanner from '@/components/ImpersonateBanner.vue'
    import UserFooter from '@/components/UserFooter.vue'
    import UserMenu from '@/components/UserMenu.vue'
    import UserProfileDropdown from '@/components/UserProfileDropdown.vue'

    import { onMounted, ref } from 'vue'
    import { useCookie } from 'vue-cookie-next'
    import { useRoute, useRouter } from 'vue-router'

    import axios, { AxiosError } from 'axios'
    import { Modal, Tooltip } from 'bootstrap'
    import DataTable from 'datatables.net-vue3'
    import DataTablesCore from 'datatables.net-bs5'
    import { MbscAlert, MbscSnackbar } from '@/assets/mobiscroll/js/mobiscroll.vue.min.js'

    const cookies = useCookie()
    const route = useRoute()
    const router = useRouter()
    DataTable.use(DataTablesCore)

    const emptyProduct: Readonly<ProductDto> = {
        id: null,
        companyId: null,
        asin: '',
        sku: '',
        map: null,
        catalogStatus: '',
        title: '',
        brandName: '',
        imageUrl: '',
        isFavorite: null,
        isEnforcementEnabled: null
    }

    const addOrEditProduct = ref<ProductDto>(JSON.parse(JSON.stringify(emptyProduct)))
    const closeModalButton = ref<HTMLButtonElement>()
    const closeImportLogModalButton = ref<HTMLButtonElement>()
    const closeImportModalButton = ref<HTMLButtonElement>()
    const turnOffCatalogSyncModal = ref<HTMLDivElement>()

    const asinBlured = ref(false)
    const skuBlured = ref(false)
    const mapBlured = ref(false)

    const alertMessage = ref('')
    const isAlertOpen = ref(false)
    const isEditing = ref(false)
    const isLoading = ref(false)
    const isSnackbarOpen = ref(false)
    const isTestMode = ref(false)
    const maxAllowedAsins = ref(0)
    const isAllowedToLWA = ref(true)
    const hasRefreshToken = ref(true)
    const isSyncingCatalog = ref(false)
    const searchValue = ref('')
    const selectedFile = ref<File>()
    const selectedProducts = ref<Array<number>>([])
    const snackbarMessage = ref('')
    const table = ref<typeof DataTable>(DataTable)
    const tableImportLog = ref<typeof DataTable>(DataTable)

    let dt: any
    let dtImportLog: any
    onMounted(async () => {
        await handleRedirectFromAmazon();

        dt = table.value.dt
        dtImportLog = tableImportLog.value.dt
        dt.on('click', '#favorite-icon', function (e: any) {
            let id = dt.row(e.target.closest('tr')).data().id
            let favorite = !(dt.row(e.target.closest('tr')).data().isFavorite)
            onFavoriteIconClicked(id, favorite)
        })
        dt.on('click', '#btn-edit', function (e: any) {
            let productRowJson = dt.row(e.target.closest('tr')).data()
            onEditProductClicked(productRowJson)
        })
        dt.on('click', '#btn-delete', function (e: any) {
            let id = dt.row(e.target.closest('tr')).data().id
            onDeleteProductClicked(id)
        })
        dt.on('click', '#cb', function (e: any) {
            let id = dt.row(e.target.closest('tr')).data().id
            onCheckboxCheckedChanged(id, e.target.checked)
        })
        dt.on('click', '#cb-select-all', function (e: any) {
            let isChecked = e.target.checked

            dt.rows().every(function (rowIdx: any) {
                let row = dt.row(rowIdx).node()
                let id = dt.row(rowIdx).data().id
                let checkbox = row.querySelector('input[type="checkbox"]')
                checkbox.checked = isChecked
                onCheckboxCheckedChanged(id, isChecked)
            })
        })
        dt.on('draw.dt', function () {
            let allRowsChecked = true
            dt.rows().every(function (rowIdx: any) {
                let row = dt.row(rowIdx).node()
                let id = dt.row(rowIdx).data().id
                let checkbox = row.querySelector('input[type="checkbox"]')
                if (selectedProducts.value.includes(id)) {
                    checkbox.checked = true
                } else {
                    allRowsChecked = false
                }

                let catalogStatusTooltip = row.querySelector('#tooltip-catalog-status')
                if (catalogStatusTooltip) new Tooltip(catalogStatusTooltip)
                
                let enforcedTooltip = row.querySelector('#tooltip-enforced')
                if (enforcedTooltip) new Tooltip(enforcedTooltip)
            })
            let selectAllCheckbox: any = document.querySelector('#cb-select-all')
            selectAllCheckbox.checked = allRowsChecked
        })
        setInterval(() => {
            let invalidFileDiv = document.getElementById('invalid-file')

            if (dtImportLog.page.info().page === 0 && (invalidFileDiv == null || invalidFileDiv.style.display == '' || invalidFileDiv.style.display == 'none')) {
                dtImportLog.ajax.reload(null, false)
            }
        }, 15000)
    })

    const handleRedirectFromAmazon = (async () => {
        if (route.query.spapi_oauth_code) {
            if (!(route.query.state) || !(route.query.state == cookies.getCookie('state'))) {
                showAlert('State from amazon does not match cookie. Please try again.')
                window.close()
            } else {
                try {
                    await axios.put('/api/company/lwa-oauth', {
                        oAuthCode: route.query.spapi_oauth_code,
                        sellingPartnerId: route.query.selling_partner_id
                    })
                } catch (error) {
                    showSnackbar('An error occurred. Please contact support.')
                } finally {
                    const queryParams = { ...route.query }
                    delete queryParams.spapi_oauth_code
                    delete queryParams.selling_partner_id
                    delete queryParams.state
                    router.replace({ query: queryParams })
                    cookies.removeCookie('state')
                    window.close()
                }
            }
        }
    })

    const onUpgradeSubscriptionClicked = (async () => {
        try {
            await axios.post('/api/company/upgrade-subscription-request')
        } catch (error) {
            showSnackbar('An error occurred. Please contact support.')
        }
    })

    const onLinkAccountClicked = (async () => {
        try {
            const lwaInfo = (await axios.get('/api/company/lwa-info')).data

            const state = Math.random().toString(36).substring(2)
            cookies.setCookie('state', state, { expire: '30min', secure: 'true' })
            const appId = encodeURIComponent(lwaInfo.appId)
            const redirectUri = encodeURIComponent(lwaInfo.redirectUri)
            const betaVersion = lwaInfo.isBetaVersion ? '&version=beta' : ''

            const authUri = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${appId}&state=${state}&redirect_uri=${redirectUri}${betaVersion}`

            const width = 1200
            const height = 800
            const left = (screen.width - width) / 2
            const top = (screen.height - height) / 2

            window.open(authUri, '_blank', `width=${width},height=${height},left=${left},top=${top}`);

            hasRefreshToken.value = true

        } catch (error) {
            showSnackbar('An error occurred. Please contact support.')
        }
    })

    const onCatalogSyncChanged = (async () => {
        if (!isSyncingCatalog.value) {
            let modal = new Modal(turnOffCatalogSyncModal.value)
            modal.show()
        } else {
            await updateCatalogSync()
        }
    })

    const updateCatalogSync = (async () => {
        try {
            await axios.put('/api/company/catalog-sync', {
                turnOn: isSyncingCatalog.value
            })
            if (isSyncingCatalog.value) {
                showSnackbar('Catalog sync has been turned on.')
            } else {
                showSnackbar('Catalog sync has been turned off.')
            }
        } catch (error) {
            showSnackbar('An error occurred. Please contact support.')
        }
    })

    const onAddProductClicked = (() => {
        resetValidation()
        isEditing.value = false
    })

    const resetValidation = (() => {
        addOrEditProduct.value = JSON.parse(JSON.stringify(emptyProduct))
        asinBlured.value = false
        skuBlured.value = false
        mapBlured.value = false
    })

    const onFavoriteIconClicked = (async (id: number, favorite: boolean) => {
        try {
            let editProduct: ProductDto = {
                id: id,
                companyId: null,
                asin: '',
                sku: '',
                map: null,
                catalogStatus: '',
                isFavorite: favorite,
                isEnforcementEnabled: null,
                title: '',
                brandName: '',
                imageUrl: ''
            }
            await axios.put('/api/product', editProduct)
            dt.ajax.reload(null, false)

            if (favorite) {
                showSnackbar('Product was favorited.')
            } else {
                showSnackbar('Product was unfavorited.')
            }
        } catch {
            showSnackbar('An error occurred. Please contact support.')
        }
    })

    const onEditProductClicked = ((productRowJson: any) => {
        addOrEditProduct.value = JSON.parse(JSON.stringify(productRowJson))
        addOrEditProduct.value.map = addOrEditProduct.value.map.toFixed(2)
        isEditing.value = true
    })

    const onCheckboxCheckedChanged = ((id: number, isChecked: boolean) => {
        if (isChecked) {
            selectedProducts.value.push(id)
        } else {
            let index = selectedProducts.value.indexOf(id)

            if (index !== -1) {
                selectedProducts.value.splice(index, 1)
            }
        }
    })

    const onDeleteProductClicked = (async (id: number) => {
        try {
            await axios.delete('/api/product', { data: [id] })
            showSnackbar('Product was deleted.')
            await reloadTable()
        } catch (error) {
            showSnackbar('An error occurred. Please contact support.')
        }
    })

    const onDeselectAllClicked = (() => {
        selectedProducts.value = []
        dt.rows().every(function (rowIdx: any) {
            let row = dt.row(rowIdx).node()
            let checkbox = row.querySelector('input[type="checkbox"]')
            checkbox.checked = false
            let selectAllCheckbox: any = document.querySelector('#cb-select-all')
            selectAllCheckbox.checked = false
        })
    })

    const onDeleteSelectedProductsClicked = (async () => {
        try {
            await axios.delete('/api/product', { data: selectedProducts.value })
            showSnackbar('Products were deleted.')
            await reloadTable()
            closeImportModalButton.value?.click()
            selectedProducts.value = []
        } catch (error) {
            showSnackbar('An error occurred. Please contact support.')
        }
    })

    const onSubmitClicked = (async () => {
        if (!validate()) {
            return
        }
        try {
            if (isEditing.value) {
                await axios.put('/api/product', addOrEditProduct.value)
                showSnackbar('Product was updated.')
            } else {
                await axios.post('/api/product', addOrEditProduct.value)
                showSnackbar('Product was added.')
            }
            closeModalButton.value?.click()
            await reloadTable()
        } catch (error) {
            const axiosError = error as AxiosError
            if (axiosError.response?.status == 422) {
                showSnackbar(axiosError.response.data as string)
            } else {
                showSnackbar('An error occurred. Please contact support.')
            }
        }
    })

    const validate = (() => {
        asinBlured.value = true
        skuBlured.value = true
        mapBlured.value = true
        return validAsin() && validSku() && validMap()
    })

    const validAsin = (() => {
        let regex = CustomRegex.isAlphaNumericAndTenCharacters
        if (regex.test(addOrEditProduct.value.asin)) {
            return true
        }
    })

    const validSku = (() => {
        let regex = CustomRegex.isBetweenOneAndTwoHundredFiftyFourChars
        if (regex.test(addOrEditProduct.value.sku)) {
            return true
        }
    })

    const validMap = (() => {
        let regex = CustomRegex.isNumberWithTwoDecimalPlaces
        if (addOrEditProduct.value.map && regex.test(addOrEditProduct.value.map?.toString())) {
            return true
        }
    })

    const onImportClicked = (async () => {
        try {
            if (selectedFile.value == null) {
                return
            }
            isLoading.value = true
            let formData = new FormData()
            formData.append('file', selectedFile.value)
            await axios.post('/api/product/csv', formData)
            showSnackbar('Your CSV is being processed. You will receive an email when processing completes.')
            closeImportLogModalButton.value?.click()
            reloadImportLogTable()
            setTimeout(() => {
                reloadTable()
            }, 5000)
        } catch (error) {
            showSnackbar('An error occurred. Please contact support.')
        } finally {
            selectedFile.value = undefined
            isLoading.value = false
        }
    })

    const onFileChanged = (($event: Event) => {
        const target = $event.target as HTMLInputElement
        if (target && target.files) {
            selectedFile.value = target.files[0]
        }
    })

    const getPostData = ((d: any) => {
        const pageNumber = d.start / d.length + 1
        const pageSize = d.length
        const search = searchValue.value != '' ? searchValue.value : 'null'
        return JSON.stringify({ PageIndex: pageNumber, PageSize: pageSize, Search: search })
    })

    const getDataSrc = ((response: any) => {
        maxAllowedAsins.value = response.maxAllowedAsins
        isAllowedToLWA.value = response.isAllowedToLWA
        hasRefreshToken.value = response.hasRefreshToken
        isSyncingCatalog.value = response.isSyncingCatalog
        return response.productList
    })

    const getImportLogPostData = ((d: any) => {
        const pageNumber = d.start / d.length + 1
        const pageSize = d.length
        return JSON.stringify({ PageIndex: pageNumber, PageSize: pageSize })
    })

    const getImportLogDataSrc = ((response: any) => {
        return response.importLogList
    })

    const displayAjaxError = (() => {
        snackbarMessage.value = 'An error occurred when loading the table. Please contact support.'
        isSnackbarOpen.value = true
    })

    const reloadTable = (() => {
        table.value.dt.ajax.reload()
    })

    const reloadImportLogTable = (() => {
        tableImportLog.value.dt.ajax.reload()
    })

    const showAlert = ((message: string) => {
        alertMessage.value = message
        isAlertOpen.value = true
    })

    const showSnackbar = ((msg: string) => {
        snackbarMessage.value = msg
        isSnackbarOpen.value = true
    })

    const tableColumns = ref([
        {   // Responsive control column
            className: 'control',
            data: null,
            defaultContent: '',
            orderable: false
        },
        {   // Checkbox select column
            className: 'select-checkbox',
            data: null,
            defaultContent: '<input type="checkbox" id="cb" name="cb[]" value="x">',
            orderable: false
        },
        {
            className: 'title-column',
            data: 'title',
            render: function (data: any, type: any, row: any) {
                let favoriteIcon = row.isFavorite ? '<a id="favorite-icon" class="favorite-icon" role="button" href="javascript:void(0)"><i class="fas fa-star"></i></a>'
                    : '<a id="favorite-icon" class="favorite-icon" role="button" href="javascript:void(0)"><i class="far fa-star"></i></a>'

                return `<div class="row align-items-center">
                    <div class="col-auto">
                        ${favoriteIcon}
                    </div>
                    <div class="col-2">
                        <img src="${row.imageUrl}" alt="" />
                    </div>
                    <div class="col">
                    <p style="font-size: 13px; font-weight: 500; margin: 0;"><span class="me-3">ASIN: ${row.asin}</span> SKU: ${row.sku}</p>
                    <p class="text-body product-title"><a style="color: inherit;" href="javascript:void(0)" onclick="window.open('https://www.amazon.com/dp/${row.asin}', '_blank')">${data}</a></p>
                    </div>
                </div>`
            }
        },
        {
            className: 'brand-name-column',
            data: 'brandName'
        },
        {
            className: 'map-column',
            data: 'map',
            render: function (data: any) {
                // Format the data as currency
                return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data)
            }
        },
        {
            data: 'isBrandApproved',
            render: function (data: any, type: any, row: any) {
                return row.isBrandApproved && row.isEnforcementEnabled ? `<div class="d-flex">
						    <img id="tooltip-enforced" class="shield-icon" style="max-width: unset;" alt="Enforced" data-bs-toggle="tooltip" data-bs-title="Is Enforced" src='data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMDAgMjUwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmMDAzMDtzdHJva2Utd2lkdGg6MHB4O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0yMDAsNjMuOTd2LTEzLjk3TDEwMCwwbC0uMTQuMDctNi43NywzLjM4TDAsNTBoMHYzMy43MmMwLDEuNTYuMDIsMy4xMy4wNiw0LjY5LjA4LDMuMTIuMjUsNi4yMy41LDkuMzIuMTMsMS41NS4yNywzLjA5LjQ0LDQuNjMuMTksMS43Ny40MiwzLjUzLjY2LDUuMjkuMzksMi44My44NSw1LjY1LDEuMzgsOC40NS4yOSwxLjUxLjU5LDMuMDIuOTIsNC41Mi45MSw0LjE3LDEuOTgsOC4zLDMuMTksMTIuMzguMjQuNzkuNDgsMS41OS43MywyLjM4LDEuMSwzLjUyLDIuMzEsNy4wMSwzLjYzLDEwLjQ1LjM3Ljk3Ljc2LDEuOTQsMS4xNSwyLjkuNjQsMS41OCwxLjMsMy4xNSwxLjk5LDQuNzIuNjksMS41NiwxLjM5LDMuMTEsMi4xMiw0LjY2LDEuODQsMy44OCwzLjgyLDcuNjgsNS45NCwxMS40Mi43MSwxLjI0LDEuNDMsMi40OCwyLjE3LDMuNzEsMi4yOSwzLjgxLDQuNzQsNy41NCw3LjMzLDExLjE4LDcuNzYsMTAuOTEsMTYuODMsMjAuOTgsMjcuMDksMjkuOTZsMy42MiwzLjE3LDE4LjU1LDE2LjIzLDE4LjU1LDE2LjIzLDEwLjE3LTguODktMTAuMTctNS4wN3YtMjUuMzVsMjguNjIsMTQuMjcsMTAuMTctOC45LTM4Ljc5LTE5LjM0di0yNS4zNWw1NS45NSwyNy44OWMyLjg2LTMuMiw1LjU4LTYuNSw4LjE4LTkuODlsLTY0LjEzLTMxLjk3di0yNS4zNWw3Ni44MiwzOC4zYzIuMDgtMy42MSw0LjA0LTcuMyw1Ljg1LTExLjA1bC04Mi42OC00MS4yMnYtMjUuMzVsOTEuMjIsNDUuNDhjMS4zMy00LjAyLDIuNS04LjA5LDMuNTQtMTIuMmwtOTQuNzYtNDcuMjV2LTI1LjM1bDk4Ljk1LDQ5LjMzYy40OS00LjQ3LjgxLTguOTcuOTUtMTMuNDlsLTk5LjktNDkuODFWMTQuMTFsMTAwLDQ5Ljg2WiIvPjwvc3ZnPg=='/>
						</div>` : `<div class="d-flex">
                            <img id="tooltip-enforced" class="shield-icon" style="max-width: unset;" alt="Enforced" data-bs-toggle="tooltip" data-bs-title="Not Enforced" src='data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMDAgMjUwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2QxZDdkYjtzdHJva2Utd2lkdGg6MHB4O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0yMDAsNjMuOTd2LTEzLjk3TDEwMCwwbC0uMTQuMDctNi43NywzLjM4TDAsNTBoMHYzMy43MmMwLDEuNTYuMDIsMy4xMy4wNiw0LjY5LjA4LDMuMTIuMjUsNi4yMy41LDkuMzIuMTMsMS41NS4yNywzLjA5LjQ0LDQuNjMuMTksMS43Ny40MiwzLjUzLjY2LDUuMjkuMzksMi44My44NSw1LjY1LDEuMzgsOC40NS4yOSwxLjUxLjU5LDMuMDIuOTIsNC41Mi45MSw0LjE3LDEuOTgsOC4zLDMuMTksMTIuMzguMjQuNzkuNDgsMS41OS43MywyLjM4LDEuMSwzLjUyLDIuMzEsNy4wMSwzLjYzLDEwLjQ1LjM3Ljk3Ljc2LDEuOTQsMS4xNSwyLjkuNjQsMS41OCwxLjMsMy4xNSwxLjk5LDQuNzIuNjksMS41NiwxLjM5LDMuMTEsMi4xMiw0LjY2LDEuODQsMy44OCwzLjgyLDcuNjgsNS45NCwxMS40Mi43MSwxLjI0LDEuNDMsMi40OCwyLjE3LDMuNzEsMi4yOSwzLjgxLDQuNzQsNy41NCw3LjMzLDExLjE4LDcuNzYsMTAuOTEsMTYuODMsMjAuOTgsMjcuMDksMjkuOTZsMy42MiwzLjE3LDE4LjU1LDE2LjIzLDE4LjU1LDE2LjIzLDEwLjE3LTguODktMTAuMTctNS4wN3YtMjUuMzVsMjguNjIsMTQuMjcsMTAuMTctOC45LTM4Ljc5LTE5LjM0di0yNS4zNWw1NS45NSwyNy44OWMyLjg2LTMuMiw1LjU4LTYuNSw4LjE4LTkuODlsLTY0LjEzLTMxLjk3di0yNS4zNWw3Ni44MiwzOC4zYzIuMDgtMy42MSw0LjA0LTcuMyw1Ljg1LTExLjA1bC04Mi42OC00MS4yMnYtMjUuMzVsOTEuMjIsNDUuNDhjMS4zMy00LjAyLDIuNS04LjA5LDMuNTQtMTIuMmwtOTQuNzYtNDcuMjV2LTI1LjM1bDk4Ljk1LDQ5LjMzYy40OS00LjQ3LjgxLTguOTcuOTUtMTMuNDlsLTk5LjktNDkuODFWMTQuMTFsMTAwLDQ5Ljg2WiIvPjwvc3ZnPg=='/>
                        </div>`
            }
        },
        {
            data: 'catalogStatus',
            render: function (data: any) {
                return data == 'Active' ? `<div class="d-flex">
						    <span id="tooltip-catalog-status" data-bs-toggle="tooltip" data-bs-title="Real-Time Price Updates"><i class="far fa-stopwatch fa-lg"></i></span>
						</div>` : ''                    
             }
        },
        {
            className: 'action-column',
            data: null,
            defaultContent: `
                     <div class="dropdown">
                         <button class="btn btn-white border-0 action-btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                             <i class="far fa-ellipsis-v fa-lg"></i>
                         </button>
                         <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                             <li><a class="dropdown-item"
                                     id="btn-edit"
                                     data-bs-toggle="modal"
                                     data-bs-target="#edit-product"
                                     href="#"
                                     ><i class="fa-light fa-pencil fa-fw"></i> Edit</a></li>
                             <li><a class="dropdown-item"
                                     id="btn-delete"
                                     href="#"><i class="fa-light fa-trash-alt fa-fw"></i> Delete</a></li>
                         </ul>
                     </div>
                 `
        }
    ])
    const tableOptions = ref({
        responsive: true,
        dom: 'rtipl',
        processing: true,
        serverSide: true,
        ajax: {
            url: `/api/product/page`,
            type: 'POST',
            contentType: 'application/json',
            data: (d: any) => getPostData(d),
            dataSrc: (json: any) => getDataSrc(json),
            error: () => isTestMode.value ? {} : displayAjaxError(),
            columns: tableColumns.value
        },
        language: {
            lengthMenu: '_MENU_'
        },
        lengthMenu: [10, 50, 100, 1000],
        select: {
            style: 'multi',
            selector: 'td.select-checkbox'
        },
    })

    const tableColumnsImportLog = ref([
        {
            data: 'importDate',
            render: function (data: any) {
                // Format the data as human readable date string
                return new Date(data).toLocaleString()
            }
        },
        {
            data: 'originalFileName',
            render: function (data: any, type: any, row: any) {
                return `<a href="${row.originalFileUrl}" target="_blank">${data}</a>`
            }
        },
        {
            data: 'status',
            render: function (data: any, type: any, row: any) {
                let errorModal = `<div class="modal fade" data-bs-backdrop="static" id="invalid-file" tabindex="-1" aria-labelledby="invalid-file" aria-hidden="true">
                      <div class="modal-dialog modal-lg modal-dialog-centered">
                          <div class="modal-content">
                              <div class="modal-header">
                                  <h1 class="modal-title fs-3">Invalid File</h1>
                                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div class="modal-body">
                                  <p>${row.erroredOutMessage}</p>
                                  <p>If you have any questions, please send this error message along with your original csv file to support.</p>
                              </div>
                              <div class="modal-footer">
                                  <button type="button"
                                          class="btn btn-outline-secondary btn-round me-2"
                                          data-bs-dismiss="modal">Cancel</button>
                              </div>
                          </div>
                      </div>
                  </div>`

                return !row.erroredOut ? data : `${errorModal}
                  <a id="btn-invalid-file"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#invalid-file"><span class="text-danger text-500 me-3"><i class="far fa-exclamation-triangle"></i></span>Error</a>`
            }
        },
        {
            data: 'totalRowsProcessed',
            render: function (data: any, type: any, row: any) {
                return !row.erroredOut ? data : ''
            }
        },
        {
            data: 'successfulRowsProcessed',
            render: function (data: any, type: any, row: any) {
                return !row.erroredOut ? data : ''
            }
        },
        {
            data: 'failedRowsProcessed',
            render: function (data: any, type: any, row: any) {
                let html = ''
                if (data != null && data > 0) {
                    html = `<div class="d-flex align-items-center">
                      <span class="text-danger text-500 me-3"><i class="far fa-exclamation-triangle"></i> ${data}</span>
                      <a href="${row.failedFileUrl}" target="_blank" role="button" class="btn btn-sm btn-outline-secondary btn-round"><i class="far fa-download"></i>  download</a>
                  </div>`
                } else {
                    html = data
                }
                return !row.erroredOut ? html : ''
            }
        },
    ])
    const tableOptionsImportLog = ref({
        responsive: true,
        dom: 'rtipl',
        processing: true,
        serverSide: true,
        ajax: {
            url: `/api/product/import-log/page`,
            type: 'POST',
            contentType: 'application/json',
            data: (d: any) => getImportLogPostData(d),
            dataSrc: (json: any) => getImportLogDataSrc(json),
            error: () => isTestMode.value ? {} : displayAjaxError(),
            columns: tableColumnsImportLog.value
        },
        language: {
            lengthMenu: '_MENU_'
        },
        lengthMenu: [5, 10, 50, 100],
        pageLength: 5
    })

</script>